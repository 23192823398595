// ===================
// Circles List slider
// ===================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.CirclesList-slider');

  for (const slider of sliders) {

    const sliderContainer = slider.querySelector('.Slider-container');
    const options = {
      slidesPerView: 2.4,
      spaceBetween: 34,
      watchOverflow: true,
      breakpoints: {
        700: {
          slidesPerView: 3.4,
          spaceBetween: 30
        },
        900: {
          slidesPerView: 4.4
        },
        1100: {
          slidesPerView: 5
        }
      },
      navigation: {
        prevEl: slider.querySelector('.Slider-buttonPrev'),
        nextEl: slider.querySelector('.Slider-buttonNext')
      },
      pagination: {
        el: slider.querySelector('.Slider-bullets'),
        type: 'bullets',
        bulletElement: 'li',
        bulletClass: 'Slider-bullet',
        bulletActiveClass: 'Slider-bullet--active',
        clickable: true
      }
    };

    const newCirclesListSlider = new Swiper(sliderContainer, options)

    checkBullets(slider, newCirclesListSlider);


  }

  let ageSelector = $('#AgeSelector')
  if(ageSelector.length > 0){
    let activitiesDropdown = ageSelector.find('[data-dropdown],[data-select-dropdown]')[0];
    let spanPlaceholder = ageSelector.find('.agePlaceholder')
    ageSelector.on('change',(e) => {
      // Don't know why, but activitiesDropdown is not accessible by this scope in production build environment
      let clickedInput = $(e.target);
      let inputLabel = clickedInput.next()
      spanPlaceholder.text(inputLabel.text())
      $('#AgeSelector').find('[data-dropdown],[data-select-dropdown]')[0].foundationDropdown.close()
    })
  }
}

function checkBullets(slider, initializedSlider) {
  const slidesLength = initializedSlider.slides.length;
  let viewLimit = '';

  if (window.matchMedia('(min-width: 1100px)').matches) {
    viewLimit = 5
  } else if (window.matchMedia('(min-width: 900px) and (max-width: 1099px)').matches) {
    viewLimit = 4
  } else if (window.matchMedia('(min-width: 700px) and (max-width: 899px)').matches) {
    viewLimit = 3
  } else if (window.matchMedia('(max-width: 699px)').matches) {
    viewLimit = 2
  }

  if (slidesLength < viewLimit) {
    slider.querySelector('.Slider-bullets').style.display = 'none';
    slider.querySelector('.Slider-buttonNext').style.display = 'none';
    slider.querySelector('.Slider-buttonPrev').style.display = 'none';

  }
}
