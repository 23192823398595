// ==========================
// Homepage Characters slider
// ==========================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.HomepageCharacters-slider');

  for (const slider of sliders) {

    const sliderContainer = slider.querySelector('.Slider-container');
    const options = {
      slidesPerView: 2.4,
      spaceBetween: 20,
      watchOverflow: true,
      breakpoints: {
        700: {
          slidesPerView: 3.4,
          spaceBetween: 30
        },
        900: {
          slidesPerView: 4.4
        },
        1100: {
          slidesPerView: 'auto'
        }
      }
    };

    const breakpoint = window.matchMedia('(min-width: 1100px)');
    let newHomepageCharactersSlider;

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        if (newHomepageCharactersSlider !== undefined) newHomepageCharactersSlider.destroy(true, true);
        return;
      } else if (breakpoint.matches === false) {
        return enableSwiper();
      }
    };

    const enableSwiper = function () {
      newHomepageCharactersSlider = new Swiper(sliderContainer, options)
    };

    breakpointChecker();
    breakpoint.onchange = breakpointChecker();
  }
}