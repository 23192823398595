// ========================
// Novelty Slider component
// ========================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.NoveltySlider');

  for (const slider of sliders) {
    const sliderContainer = slider.querySelector('.NoveltySlider-container'),
          bullets = slider.querySelector('.NoveltySlider-bullets'),
          buttons = slider.querySelectorAll('.NoveltySlider-button');

    const newSlider = new Swiper(sliderContainer, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      watchOverflow: true,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '.NoveltySlider-nextButton',
        prevEl: '.NoveltySlider-prevButton',
      },
      pagination: {
        el: '.NoveltySlider-bullets',
        type: 'bullets',
        clickable: true
      }
    });

    // Stop autoplay on mouse enter
    sliderContainer.addEventListener('mouseenter', function() {
      newSlider.autoplay.stop();
    });

    // Show arrows on mouse enter
    slider.addEventListener('mouseenter', function () {
      slider.classList.add('hover');
    });

    // Listen to click before mouse leave
    let bulletsClicked = 0;
    bullets.addEventListener('click', function() {
      bulletsClicked += 1;
    });
    for (const button of buttons) {
      button.addEventListener('click', function () {
        bulletsClicked += 1;
      });
    }

    sliderContainer.addEventListener('mouseleave', function() {
      if (bulletsClicked === 0) {
        newSlider.autoplay.start();
      }
    });

    // Hide arrows on mouse leave
    slider.addEventListener('mouseleave', function () {
      slider.classList.remove('hover');
    });
  }
}