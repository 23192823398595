// =====================================
// Inspirations script
// use TypeIt library (typewriter style)
// =====================================

import $ from 'jquery';
import TypeIt from 'typeit';

if(location.pathname == '/') {
    $('#InspirationsPanel').remove()
    let INSPIRATIONAL_BUTTON = $('.Header-links .Highlight')

    if(INSPIRATIONAL_BUTTON.length > 0) {
        INSPIRATIONAL_BUTTON.remove()
    }
}

let $inspirations = $('.Inspirations');
if ($inspirations.length > 0) {

    let typeItTimeout = null;
    let loopStop = false;

    let recommandationsData = $('.Inspirations').data('recommandations');
    let recommandationsUrl = recommandationsData['url'];
    let recommandations = recommandationsData['items'];

    const ageTarget = $('#InspirationsAge');
    let $thematicInput = $('#Inspirations-thematicInput');
    let ageToSubmit = 0;

    if (ageTarget.length > 0) {
        let inspirationsThematic = new TypeIt('#InspirationsThematic', {
            speed: 200,
            breakLines: false,
            waitUntilVisible: true,
            loop: false
        });

        function stop() {
            loopStop = true;
            clearTimeout(typeItTimeout);
            inspirationsThematic.freeze();
        }

        let index = 0;
        if (recommandations.length >= 1) {

            $('.Inspirations-age').parent().on('click', stop);

            $('#InspirationsThematic').on('click', function (e) {
                stop();
                inspirationsThematic.destroy();

                $thematicInput.val('');
                $thematicInput.attr('placeholder', 'saisir un mot-clé (ou non)');
                $(this).parent().find('p').attr('aria-hidden', 'false');

                // Use setTimeout to fix bug. Cannot be focus while element not visible
                setTimeout(function () {
                    $thematicInput.focus();
                }, 50);
            });

            let fonctionInspirationAction = function () {
                stop();

                let url = new URL(window.location.origin + window.location.pathname);
                url.pathname = recommandationsUrl;
                url.searchParams.append('text', $thematicInput.val());
                url.searchParams.append('age', ageToSubmit);
                url.searchParams.append('bookOnly', true);
                window.location.href = url.toString();

                let recommandation = recommandations[index];
                $('.Inspirations-thematicInput input').attr('placeholder', recommandation.text);
            }

            $('.Inspirations-submit a').on('click', function () {
                fonctionInspirationAction();
            });

            $('#Inspirations-thematicInput').on('keypress', function (e) {
                if(e.which === 13) {
                    fonctionInspirationAction();
                }
            })


            let type = function () {

                if (index >= recommandations.length) {
                    index = 0;
                }

                let recommandation = recommandations[index];
                ageToSubmit = recommandation.age;
                ageTarget.text(recommandation.ageText);

                inspirationsThematic.reset();
                let queueLength = inspirationsThematic.getQueue().getItems().length;
                for (let i = 0; i < queueLength; i++) {
                    inspirationsThematic.getQueue().delete(0);
                }

                ageTarget.fadeIn(500, function () {
                    $thematicInput.val(recommandation.text);
                    inspirationsThematic.type([recommandation.text], {
                        delay: 500,
                        afterString: (step, instance) => {
                            typeItTimeout = !loopStop && setTimeout(function () {
                                index++;
                                ageTarget.fadeOut(500, type);
                            }, 2000);

                        }
                    }).go();
                });
            };

            !loopStop && type();
        }

        $('.Inspirations').on('click', '.Inspirations-agesList li button', function () {
            stop();

            ageTarget.show();
            ageToSubmit = $(this).parent().data('value');
            ageTarget.text($(this).text());
        });
    }
}
