// Enable SVG <use xlink:href> support in IE11
import svg4everybody from "svg4everybody";
svg4everybody();

// Import each icon that we want to include in our SVG sprite
// (built by Webpack + svg-sprite-loader)
import "./arrow.svg";
import "./arrow-backtotop.svg";
import "./arrow-bold.svg";
import "./arrow-short.svg";
import "./audio.svg";
import "./audiobook.svg";
import "./book.svg";
import "./clock.svg";
import "./cross.svg";
import "./email.svg";
import "./facebook.svg";
import "./gallimard-teaching.svg";
import "./heart.svg";
import "./instagram.svg";
import "./logo.svg";
import "./logo2.svg";
import "./menu.svg";
import "./pause.svg";
import "./play.svg";
import "./play-video.svg";
import "./quote.svg";
import "./search.svg";
import "./twitter.svg";
import "./youtube.svg";
import "./trash.svg";
import "./decorators_bird.svg";
import "./decorators_castle.svg"
import "./decorators_cat.svg";
import "./decorators_commence_aventure_ici.svg";
import "./decorators_frog.svg";
import "./decorators_hat.svg";
import "./decorators_moon.svg";
import "./decorators_nine.svg";
import "./decorators_owl.svg";
import "./decorators_railway.svg";
import "./decorators_ribbon.svg";
import "./decorators_star_ribbon2.svg";
import "./decorators_teste_connaissances.svg";
import "./decorators_teste_connaissances_2.svg";
import "./edwige.svg";
import "./fleche.svg";
import "./le_livre.svg";
import "./les_quiz.svg";
import "./ornaments_3.svg";
import "./stars_1.svg";
import "./stars_2.svg";
import "./stars_3.svg";
import "./stars_5.svg";
import "./stars_5_2.svg";
import "./stars_5_3.svg";
import "./correct.svg";
import "./incorrect.svg";
import "./hp_tome_1.svg";
import "./hp_tome_2.svg";
import "./hp_activities.svg";

