// ====================
// Homepage News script
// ====================

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const newsList = document.querySelectorAll('.HomepageNews-list');

  for (const newsItem of newsList) {
    const newsText = newsItem.querySelectorAll('.HomepageNews-listItem .NewsItem-text');

    for (const text of newsText) {
      const newsImage = text.previousElementSibling;
      text.style.height = 'calc(100% - ' + newsImage.offsetHeight + 'px)';
    }

    window.addEventListener('resize', function() {
      for (const text of newsText) {
        const newsImage = text.previousElementSibling;
        text.style.height = 'calc(100% - ' + newsImage.offsetHeight + 'px)';
      }
    });
  }
}