// =======================
// Websites List component
// =======================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.WebsitesList');

  for (const slider of sliders) {
    const sliderContainer = slider.querySelector('.WebsitesList-container');

    const websitesListSlider = new Swiper(sliderContainer, {
      slidesPerView: 1.15,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        700: {
          slidesPerView: 2.25
        },
        900: {
          slidesPerView: 3.25
        },
        1100: {
          slidesPerView: 4
        }
      }
    });
  }
}