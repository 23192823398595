// ========
// Homepage
// ========

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}


function init() {
  const homepage = document.querySelector('.Homepage');

  if (homepage == null) {
    // if (window.matchMedia('(max-width: 1100px)').matches) {
      const inspirations = document.querySelector('.Header-inspirations');
      if (!inspirations) {
          return;
      }
      inspirations.classList.add('Header-inspirations--active');
      // document.body.classList.add('Homepage-selector')
    // }
  }
}