// ==================
// Inspirations Panel
// ==================

import $ from 'jquery';
import { Dropdown } from '../../../../scripts/main/foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const inspirations = $('[data-inspirations-dropdown]');
  if (inspirations.length === 0) {
    return;
  }

  // const menuItem = $('.Menu-item'),
  //   menuFirstItem = $('.Menu-item--first');
  const inspirationsOptions = {
    closeOnClick: true,
    closeOnClickInside: false,
  };

  const inspirationsInit = new Dropdown(inspirations, inspirationsOptions);

  // Open state
  inspirations.on('show.zf.dropdown', function() {
    inspirations.children().fadeOut().slideDown(400);
    // menuFirstItem.addClass('is-active');

    if (window.matchMedia('(max-width: 700px)').matches) {
      $('body').addClass('Locked');
    }
  });

  // Close state
  inspirations.on('hide.zf.dropdown', function() {
    inspirations.children().fadeIn().slideUp(300);
    // menuItem.removeClass('is-active').attr('data-is-click', 'false');
    // menuItem.children('.Menu-level').removeClass('js-dropdown-active');

    if (window.matchMedia('(max-width: 700px)').matches) {
      $('body').removeClass('Locked');
    }
  });
}