// ====================
// Articles List script
// ====================

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    let callback = function (mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                for (const articlesItem of mutation.addedNodes) {
                    parse(articlesItem);
                }
            }
        }
    };

    const articlesList = document.querySelectorAll('.ArticlesList');
    let observer = new MutationObserver(callback);
    for (const articlesItem of articlesList) {
        parse(articlesItem);
        observer.observe(articlesItem, {attributes: false, childList: true});
    }
}

function parse(articlesItem) {
    window.addEventListener('resize', function () {
        for (const text of articlesText) {
            text.style.height = 'calc(100% - ' + articlesImage.offsetHeight + 'px)';
        }
    });
}

