// ===================
// Inspirations Canvas
// ===================

import $ from 'jquery';
import { OffCanvas } from '../../../../scripts/main/foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  if ($('[data-inspirations-canvas]').length) {
    var offcanvas = $('[data-inspirations-canvas]');

    const offcanvasOptions = {
      transition: 'overlap',
      transitionTime: '.25s',
    };

    if (Foundation.MediaQuery.get('medium') && Foundation.MediaQuery.upTo('medium')) {
      const offcanvasInit = new OffCanvas(offcanvas, offcanvasOptions);
      lockBody();
    }
    $(window).on('changed.zf.mediaquery', function() {
      if (Foundation.MediaQuery.get('medium') && Foundation.MediaQuery.upTo('medium')) {
        const offcanvasInit = new OffCanvas(offcanvas, offcanvasOptions);
        lockBody();
      } else {
        if (offcanvas.attr('data-off-canvas')) {
          offcanvas.foundation('_destroy');
        }
      }
    });
  }

  function lockBody() {
    offcanvas.on('opened.zf.offCanvas', function() {
      $('body').addClass('Locked');
    });
    offcanvas.on('closed.zf.offCanvas', function() {
      $('body').removeClass('Locked');
    });
  }
}


