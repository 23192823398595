// ===================
// Audio Player script
// ===================

import GreenAudioPlayer from 'green-audio-player';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {

  GreenAudioPlayer.init({
    selector: '.AudioPlayer',
    showDownloadButton: false,
    showTooltips: true,
    stopOthersOnPlay: true
  });

  const allPlayers = document.querySelectorAll('.AudioPlayer');

  allPlayers.forEach(function(player) {
    updateText(player);
  });

  document.addEventListener('click', function() {
    allPlayers.forEach(function(player) {
      updateText(player);
    });
  });

  function updateText(item) {
    const play = item.querySelector('.play-pause-btn[aria-label="Play"]'),
          pause = item.querySelector('.play-pause-btn[aria-label="Pause"]'),
          volume = item.querySelector('.volume__button'),
          volumeOpened = item.querySelector('.volume__button.open');

    if (play) { play.title = 'Lancer la lecture'; }
    if (pause) { pause.title = 'Mettre en pause'; }
    if (volume) { volume.title = 'Ouvrir le contrôle du volume'; }
    if (volumeOpened) { volumeOpened.title = 'Fermer le contrôle du volume'; }
  }
}